define('ember-side-menu/components/side-menu', ['exports', 'ember-side-menu/utils/gestures'], function (exports, _gestures) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember;

  var styleProps = ['shadowStyle', 'positionStyle', 'transitionStyle', 'transformStyle'];

  exports.default = Ember.Component.extend({
    sideMenu: Ember.inject.service(),

    menu: Ember.computed('id', 'sideMenu.menus', function () {
      var menuId = Ember.get(this, 'id');
      return Ember.get(this, 'sideMenu.menus.' + menuId);
    }),
    progress: Ember.computed.alias('menu.progress'),
    isOpen: Ember.computed.oneWay('menu.isOpen'),
    isClosed: Ember.computed.oneWay('menu.isClosed'),
    isSlightlyOpen: Ember.computed.alias('menu.isSlightlyOpen'),
    isTouching: false,
    disableMenu: false,

    attributeBindings: ['style'],
    classNames: ['side-menu'],
    classNameBindings: ['isInProgress:disable-scroll'],

    id: 'default',
    side: 'left',
    width: '70%',
    rootNodeSelector: 'body',

    initialTapAreaWidth: 30,
    slightlyOpenWidth: 20,
    slightlyOpenAfter: 300,

    shadowStyle: Ember.computed('progress', function () {
      var progress = Ember.get(this, 'progress');
      return progress === 0 ? 'box-shadow: none;' : '';
    }),

    positionStyle: Ember.computed('width', 'side', function () {
      var width = Ember.get(this, 'width');
      var side = Ember.get(this, 'side');

      if (side === 'left') {
        return 'width: ' + width + '; right: initial; left: -' + width + ';';
      }

      return 'width: ' + width + '; left: initial; right: -' + width + ';';
    }),

    transitionStyle: Ember.computed('progress', function () {
      var progress = Ember.get(this, 'progress');
      return progress === 0 || progress === 100 ? 'transition: transform 0.2s ease-out;' : 'transition: none;';
    }),

    transformStyle: Ember.computed('progress', 'side', 'isSlightlyOpen', function () {
      var side = Ember.get(this, 'side');
      var isSlightlyOpen = Ember.get(this, 'isSlightlyOpen');
      var slightlyOpenWidth = Ember.get(this, 'slightlyOpenWidth');
      var direction = side === 'right' ? '-' : '';
      var unit = isSlightlyOpen ? 'px' : '%';
      var progress = isSlightlyOpen ? slightlyOpenWidth : Ember.get(this, 'progress');

      return 'transform: translateX(' + direction + progress + unit + ');';
    }),

    style: (_Ember = Ember).computed.apply(_Ember, styleProps.concat([function () {
      var _this = this;

      var combinedStyle = styleProps.reduce(function (acc, style) {
        return acc + Ember.get(_this, style);
      }, '');

      return Ember.String.htmlSafe(combinedStyle);
    }])),

    _setScrollDisable: function _setScrollDisable() {
      var isClosed = Ember.get(this, 'isClosed');
      var wasClosed = Ember.get(this, 'wasClosed');

      if (isClosed === wasClosed) return;

      var rootNode = document.querySelector(Ember.get(this, 'rootNodeSelector'));

      if (isClosed) {
        rootNode.classList.remove('disable-scroll');
      } else {
        rootNode.classList.add('disable-scroll');
      }

      Ember.set(this, 'wasClosed', isClosed);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._initMenu();
      this._setupEventListeners();
      this._setupObservers();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._destroyMenu();
      this._removeEventListeners();
      this._removeObservers();
    },
    _initMenu: function _initMenu() {
      var sideMenu = Ember.get(this, 'sideMenu');
      sideMenu.create(Ember.get(this, 'id'));
    },
    _destroyMenu: function _destroyMenu() {
      var sideMenu = Ember.get(this, 'sideMenu');
      sideMenu.destroy(Ember.get(this, 'id'));
    },
    _setupEventListeners: function _setupEventListeners() {
      var _this2 = this;

      var rootNode = document.querySelector(Ember.get(this, 'rootNodeSelector'));
      var onRootNodeTouch = Ember.run.bind(this, this._onRootNodeTouch);

      rootNode.addEventListener('touchstart', onRootNodeTouch);

      Ember.run.schedule('afterRender', function () {
        Ember.set(_this2, 'onTouchStart', onRootNodeTouch);
      });
      var onMenuScroll = function onMenuScroll() {
        if (!Ember.get(_this2, 'disableMenu') && !Ember.get(_this2, 'isInProgress')) {
          var enableMenuOnce = function enableMenuOnce(e) {
            Ember.set(_this2, 'disableMenu', false);
            e.target.removeEventListener(e.type, enableMenuOnce);
          };
          Ember.set(_this2, 'disableMenu', true);
          Ember.get(_this2, 'element').addEventListener('touchend', enableMenuOnce);
        }
      };
      Ember.get(this, 'element').addEventListener('scroll', onMenuScroll);
    },
    _setupObservers: function _setupObservers() {
      this._setScrollDisable();
      this.addObserver('isClosed', this, '_setScrollDisable');
    },
    _removeObservers: function _removeObservers() {
      this.removeObserver('isClosed', this, '_setScrollDisable');
    },
    _removeEventListeners: function _removeEventListeners() {
      var onTouchStart = Ember.get(this, 'onTouchStart');
      var rootNode = document.querySelector(Ember.get(this, 'rootNodeSelector'));

      rootNode.removeEventListener('touchstart', onTouchStart);
    },
    _onRootNodeTouch: function _onRootNodeTouch(evt) {
      var _this3 = this;

      var runOpenMenuSlightly = void 0;
      var rootNode = document.querySelector(Ember.get(this, 'rootNodeSelector'));
      var onTouchMove = function onTouchMove(event) {
        if (runOpenMenuSlightly) {
          Ember.run.cancel(runOpenMenuSlightly);
        }

        if (Ember.get(_this3, 'disableMenu')) return;

        if (!(_this3._isTouchWithin(event, Ember.get(_this3, 'slightlyOpenWidth')) && Ember.get(_this3, 'isClosed'))) {
          if (Ember.get(_this3, 'isSlightlyOpen')) {
            Ember.set(_this3, 'isSlightlyOpen', false);
          }

          if (!Ember.get(_this3, 'isInProgress') && _this3._isInitialGesture(event)) {
            Ember.set(_this3, 'isInProgress', true);
          }

          if (Ember.get(_this3, 'isInProgress')) {
            _this3._updateProgress(event.touches[0].pageX);
          }
        }
      };
      var onTouchEnd = function onTouchEnd(event) {
        rootNode.removeEventListener('touchmove', onTouchMove);
        rootNode.removeEventListener('touchend', onTouchEnd);
        Ember.set(_this3, 'isTouching', false);
        Ember.set(_this3, 'isInProgress', false);

        if (runOpenMenuSlightly) {
          Ember.run.cancel(runOpenMenuSlightly);
        }

        _this3._completeMenuTransition(event);
      };

      Ember.set(this, 'isTouching', true);

      if (this._validToStartTracking(evt)) {
        Ember.set(this, 'touchStartEvent', evt);
        this._setTouchOffset(evt);

        if (this._isTouchWithin(evt, Ember.get(this, 'initialTapAreaWidth'))) {
          runOpenMenuSlightly = Ember.run.later(function () {
            if (Ember.get(_this3, 'isClosed') && Ember.get(_this3, 'isTouching')) {
              Ember.set(_this3, 'isSlightlyOpen', true);
            }
          }, Ember.get(this, 'slightlyOpenAfter'));
        }

        rootNode.addEventListener('touchmove', onTouchMove);
        rootNode.addEventListener('touchend', onTouchEnd);
      }
    },
    _setTouchOffset: function _setTouchOffset(event) {
      var isOpen = Ember.get(this, 'isOpen');
      var pageX = event.touches[0].pageX;
      var side = Ember.get(this, 'side');

      if (isOpen) {
        if (side === 'left') {
          Ember.set(this, 'touchOffset', Math.max(0, this.element.offsetWidth - pageX));
        } else {
          Ember.set(this, 'touchOffset', Math.max(0, this.element.offsetWidth - (window.innerWidth - pageX)));
        }
      } else {
        Ember.set(this, 'touchOffset', 0);
      }
    },
    _updateProgress: function _updateProgress(touchPageX) {
      var elementWidth = this.element.offsetWidth;
      var touchOffset = Ember.get(this, 'touchOffset');
      var side = Ember.get(this, 'side');
      var relativeX = side === 'left' ? touchPageX : window.innerWidth - touchPageX;
      var progress = Math.min((relativeX + touchOffset) / elementWidth * 100, 100);

      Ember.set(this, 'progress', progress);
    },
    _completeMenuTransition: function _completeMenuTransition(event) {
      var progress = Ember.get(this, 'progress');
      var touchStartEvent = Ember.get(this, 'touchStartEvent');
      var side = Ember.get(this, 'side');
      var gesture = (0, _gestures.createGesture)(touchStartEvent, event);
      var minClosingVelocity = 0.3;
      var autoCompleteThreshold = 50;
      var isSwipingLeft = gesture.velocityX > minClosingVelocity;
      var isSwipingRight = gesture.velocityX < -minClosingVelocity;

      var isClosingMovement = side === 'left' && isSwipingLeft || side === 'right' && isSwipingRight;
      var isOpeningMovement = side === 'left' && isSwipingRight || side === 'right' && isSwipingLeft;

      if (isClosingMovement || progress < autoCompleteThreshold) {
        Ember.get(this, 'sideMenu').close(Ember.get(this, 'id'));
      } else if (isOpeningMovement || progress >= autoCompleteThreshold) {
        Ember.get(this, 'sideMenu').open(Ember.get(this, 'id'));
      }
    },
    _validToStartTracking: function _validToStartTracking(event) {
      return Ember.get(this, 'isOpen') || this._isTouchWithin(event, Ember.get(this, 'initialTapAreaWidth'));
    },
    _isInitialGesture: function _isInitialGesture(event) {
      var touchStartEvent = Ember.get(this, 'touchStartEvent');
      var gesture = (0, _gestures.createGesture)(touchStartEvent, event);
      var minTime = 10; // 10 ms minimum time of gesture
      var isMoreSwiping = Math.abs(gesture.velocityX) > Math.abs(gesture.velocityY);
      return gesture.time > minTime && isMoreSwiping;
    },
    _isTouchWithin: function _isTouchWithin(event, areaWidth) {
      var side = Ember.get(this, 'side');
      var pageX = event.touches[0].pageX;

      return side === 'left' && pageX < areaWidth || side === 'right' && pageX > window.innerWidth - areaWidth;
    }
  });
});