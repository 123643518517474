define('ember-side-menu/components/side-menu-link-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend({
    sideMenu: Ember.inject.service(),

    menuId: 'default',

    click: function click() {
      Ember.get(this, 'sideMenu').close(Ember.get(this, 'menuId'));
    }
  });
});