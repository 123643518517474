define('ember-side-menu/services/side-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MenuObject = Ember.Object.extend({
    id: undefined,
    progress: 0,
    isOpen: Ember.computed.equal('progress', 100),
    isClosed: Ember.computed.equal('progress', 0),
    isSlightlyOpen: false
  });

  exports.default = Ember.Service.extend({
    // backwards compability with having default menu without using menu ids
    progress: Ember.computed.alias('defaultMenu.progress'),
    isSlightlyOpen: Ember.computed.oneWay('defaultMenu.isSlightlyOpen'),
    isOpen: Ember.computed.oneWay('defaultMenu.isOpen'),
    isClosed: Ember.computed.oneWay('defaultMenu.isClosed'),

    defaultMenu: Ember.computed.oneWay('menus.default'),

    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'menus', { default: MenuObject.create({ id: 'default' }) });
    },
    create: function create() {
      var menuId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';

      var menus = Ember.get(this, 'menus');
      var newMenu = MenuObject.create({ id: menuId });
      menus[menuId] = newMenu;
      Ember.set(this, 'menus', Object.assign({}, menus));

      return newMenu;
    },
    destroy: function destroy() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';

      var menus = Ember.get(this, 'menus');

      delete menus[id];
      Ember.set(this, 'menus', Object.assign({}, menus));
    },
    close: function close() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';

      var menu = Ember.get(this, 'menus')[id];
      Ember.set(menu, 'progress', 0);
      Ember.set(menu, 'isSlightlyOpen', false);
    },
    open: function open() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';

      var menu = Ember.get(this, 'menus')[id];
      Ember.set(menu, 'progress', 100);
      Ember.set(menu, 'isSlightlyOpen', false);
    },
    toggle: function toggle() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';

      var menu = Ember.get(this, 'menus')[id];
      if (Ember.get(menu, 'isOpen')) {
        this.close(id);
      } else {
        this.open(id);
      }
    }
  });
});